<template>
  <!-- 侧导航 -->
  <div>
    <div class="flex-row navbar">
      <img class="logo" src="../static/img/logo.png" @click="$router.push('/admin-all')" style="cursor: pointer;" />
      <span class="navBarTitle" @click="$router.push('/admin-all')" style="cursor: pointer;">{{ $t("navbar.title") }}</span>
      <span class="navBarSubTitle" @click="$router.push('/admin-all')" style="cursor: pointer;">{{ $t("navbar.subTitle") }}</span>
      <div class="flex-wrap"></div>
      <el-dropdown @command="langeSwitch" class="lang-view">
        <span class="el-dropdown-link">{{ lanage }}</span>
        <i class="el-icon-arrow-down el-icon--right"></i>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="$t('navbar.langueEn')">{{
              $t("navbar.langueEn")
            }}</el-dropdown-item>
          <el-dropdown-item :command="$t('navbar.langueCn')">{{
              $t("navbar.langueCn")
            }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
      <span>{{ userInfo && userInfo.username }}</span>
      <!-- <el-dropdown @command="langeSwitch">
        <span class="el-dropdown-link">{{lanage}}</span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item :command="$t('navbar.langueHk')">{{ $t('navbar.langueHk') }}</el-dropdown-item>
          <el-dropdown-item :command="$t('navbar.langueCn')">{{ $t('navbar.langueCn') }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>-->
      <el-dropdown @command="menuSwich">
        <div class="flex-row">
          <el-avatar icon="el-icon-user-solid"></el-avatar>
          <span>{{ $t("navbar.myAccount") }}</span>
          <i class="el-icon-arrow-down" style="margin: 0 10px"></i>
        </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item class="menu-item" command="logout">{{
              $t("navbar.logout")
            }}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <el-container>
      <el-menu
          v-if="sideShow"
          background-color="#37474F"
          text-color="#fff"
          active-text-color="#81d8d0"
          :router="true"
          :default-active="'/' + this.$route.path.split('/')[1]"
      >
          <template v-for="route in pathList">
              <template v-if="route.children">
                <el-submenu :key="route.path"
                            :index="route.path"
                            :class="{ 'com-hide' : !route.showHide }">
                    <template slot="title">
                        <i :class="route.icon"></i>
                        <span slot="title">{{ $t(route.title) }}</span>
                    </template>
                    <el-menu-item v-for="cRoute in route.children"
                                  :key="cRoute.path" :index="cRoute.path"
                                  :class="{ 'com-hide' : !cRoute.showHide }">
                        <i :class="cRoute.icon"></i>
                        <span slot="title">{{ $t(cRoute.title) }}</span>
                    </el-menu-item>
                </el-submenu>
              </template>
              <template v-else>
                  <el-menu-item
                          :key="route.path"
                          :index="route.path"
                          :class="{ 'com-hide' : !route.showHide }"
                  >
                      <i :class="route.icon"></i>
                      <span slot="title">{{ $t(route.title) }}</span>
                  </el-menu-item>
              </template>
          </template>
      </el-menu>
      <el-main>
        <div class="mainContain">
          <router-view />
        </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import Storage from '../utils/storage';

// 角色:[system 1 系统管理员, generalFinance 2 普通财务管理员,advancedFinance 3 高级财务管理员,superAdmin 9 超级管理员]
export default {
  name: "admin",
  data() {
    return {
      pathList: [{
        icon: 'el-icon-menu',
        path: '/admin-all',
        title: 'adnavPage.overview',
        showHide: true
      }, {
        icon: 'el-icon-s-help',
        path: '/brand-owners-manage',
        title: 'adnavPage.brandOwnerManagement',
        showHide: sessionStorage.getItem("roleId") === "1"
      }, {
        icon: 'el-icon-s-help',
        path: '/supplier-management',
        title: 'adnavPage.supplierManagement',
        showHide: sessionStorage.getItem("roleId") === "1"
      }, {
        icon: 'el-icon-s-help',
        path: '/investor-management',
        title: 'adnavPage.investorManagement',
        showHide: sessionStorage.getItem("roleId") === "1"
      }, {
        icon: 'el-icon-tickets',
        path: '/finance-supplier-first',
        title: 'menuI18n.fmSupplierF',
        showHide: sessionStorage.getItem("roleId") === "2"
      }, {
        icon: 'el-icon-tickets',
        path: '/finance-supplier-second',
        title: 'menuI18n.fmSupplierS',
        showHide: sessionStorage.getItem("roleId") === "3"
      }, {
        icon: 'el-icon-notebook-2',
        path: '/finance-investor-first',
        title: 'menuI18n.fmInvestorF',
        showHide: sessionStorage.getItem("roleId") === "2"
      }, {
        icon: 'el-icon-notebook-2',
        path: '/finance-investor-second',
        title: 'menuI18n.fmInvestorS',
        showHide: sessionStorage.getItem("roleId") === "3"
      }, {
        icon: 'el-icon-document-copy',
        path: '/repayment-list',
        title: 'adnavPage.repaymentManage',
        showHide: sessionStorage.getItem("roleId") === "3"
      }, {
        icon: 'el-icon-coin',
        path: '/assets-list',
        title: 'adnavPage.AssetsManagement',
        showHide: sessionStorage.getItem("roleId") === "1"
      }, {
        icon: 'el-icon-s-custom',
        path: '/user-list',
        title: 'adnavPage.UserManagement',
        showHide: sessionStorage.getItem("roleId") === "9"
      }, {
        icon: 'el-icon-coin',
        path: '/token-list',
        title: 'adnavPage.TokenManagement',
        showHide: sessionStorage.getItem("roleId") === "9"
      }, {
        icon: 'el-icon-document-checked',
        path: '/mark-list',
        title: 'adnavPage.financingManage',
        showHide: sessionStorage.getItem("roleId") === "1"
      }, {
        icon: 'el-icon-office-building',
        path: '/bank',
        title: 'adnavPage.BankIM',
        showHide: sessionStorage.getItem("roleId") === "1",
          children: [{
              icon: 'el-icon-place',
              path: '/bank-area',
              title: 'adnavPage.BankRegion',
              showHide: sessionStorage.getItem("roleId") === "1"
          }, {
              icon: 'el-icon-office-building',
              path: '/bank-info',
              title: 'adnavPage.BankInfo',
              showHide: sessionStorage.getItem("roleId") === "1"
          }]
      }],
      lanage: '中文',
      userInfo: {},
    };
  },
  mounted() {
    this.lanage = Storage.getLocalItem('i18n-admin') === 'zh-cn' ? '中文' : 'English';
    this.userInfo = Storage.getSessionItem("user");
  },
  methods: {
    menuSwich(command) {
      switch (command) {
        case 'person':
          break;
        case 'manage':
          break;
        case 'logout':
          this.$store.dispatch('auth/logoutSuccess', {
            username: '',
            authorization: ''
          });
          this.$router.replace({ path: '/register' });
          break;
        default:
          break;
      }
    },
    // 多语言切换
    langeSwitch(command) {
      // this.$message({ message: this.$t('navbar.changeLangePrompt') + command, type: 'success' });
      let local = command === '中文' ? 'zh-cn' : 'zh-en';
      Storage.setLocalItem('i18n-admin', local);
      this.$i18n.locale = local;
      this.lanage = command;
      this.$store.dispatch('auth/language', local);
      window.location.reload();
    }
  },
  computed: {
    sideShow() {
      return this.$store.getters['auth/isSide'];
    }
  }
};
</script>

<style lang="scss">
.com-hide {
  display: none;
}
</style>
<style lang="scss" scoped>
.navbar {
  height: 60px;
  width: 100vw;
  min-width: 1200px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  .logo {
    width: 38px;
    height: 30px;
    margin-left: 40px;
  }
  .navBarTitle {
    margin: 0 20px;
    margin-right: 10px;
    font-size: 30px;
    font-weight: bolder;
  }
  .navBarSubTitle {
    font-size: 24px;
  }
  .el-dropdown {
    margin: 0 10px;
    .el-avatar {
      margin-right: 10px;
    }
  }
}
.menu-item {
  width: 80px;
  text-align: center;
}
.el-container {
  position: relative;
  height: calc(100vh - 80px);
  min-width: 1200px;
  margin-top: 20px;
}
.el-main {
  padding-top: 0;
}
.mainContain {
  width: 1200px;
  margin: auto;
}
.el-menu {
  width: 240px;
}
</style>
